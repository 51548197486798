@use "src/variables" as *;
$breadcrumb-height: 22px;
$breadcrumb-vertical-margin: 16px;
.root {
  position: relative;
  height: $nav-bar-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  color: white;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  background-color: #8d26d8;
}

.root:after {
  background: url("../../assets/bg1.svg") no-repeat 50%;
  background-size: cover;
  content: "";
  opacity: 100;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
}

.with-outlet-container {
  min-height: 100%;
}

.left-container {
  z-index: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
}

.right-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: white;
  z-index: 999;
}

.logo {
  width: 80px;
}

.providerLogo {
  width: 100px;
  height: 38px;
  object-fit: contain;
}

.separator {
  width: 1px;
  height: 24px;
  background-color: white;
}
