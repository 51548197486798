@use "src/variables" as *;

$content-horizontal-padding: 16px;
$tabContent-vertical-padding: 16px;

.root {
  height: calc(100vh - $nav-bar-height);
  min-height: calc(100vh - $nav-bar-height) !important;
}

.content {
  background: url("../../assets/bg1.svg") no-repeat center center;
  background-size: cover;
  height: calc(100vh - $nav-bar-height);
  min-height: calc(100vh - $nav-bar-height) !important;
  max-height: calc(100vh - $nav-bar-height) !important;
}

.tabContent {
  width: 100%;
  padding: 24px 40px !important;
  height: calc(
    100vh - $nav-bar-height - $tabContent-vertical-padding * 2
  ) !important;
  max-height: calc(
    100vh - $nav-bar-height - $tabContent-vertical-padding * 2
  ) !important;
  overflow: auto;
  padding: $tabContent-vertical-padding 0;
}

/* Customize scrollbar for a specific container */
.tabContent::-webkit-scrollbar {
  width: 12px;
}

.tabContent::-webkit-scrollbar-track {
  background: #0f1729;
}

.tabContent::-webkit-scrollbar-thumb {
  background-color: #595959;
  border-radius: 6px;
  border-left: 3px solid #1e1e1e;
  border-right: 1px solid #1e1e1e;
}

.tabContent::-webkit-scrollbar-thumb:hover {
  background-color: #616161;
}
