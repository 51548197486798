.ant-typography {
  font-weight: bold;
}

.container {
  color: rgb(240, 240, 240);
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: rgb(242, 242, 242);
  }

  h1 {
    font-size: 78px;
  }

  h2 {
    margin-bottom: 4px;
    border-bottom: 1px solid white;
  }

  h3 {
    margin-bottom: 0;
  }

  h5 {
    margin-bottom: 2px;
    color: #b7b7b7;
    font-weight: 500;
  }

  span {
    color: rgb(242, 242, 242);
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 1px;
  }
}

.alert {
  color: #ffd700;
}

.noAlert {
  color: rgb(166, 166, 166);
}
