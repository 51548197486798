.clickableHeader {
  h5 {
    margin-bottom: 2px;
    color: #b7b7b7;
    font-weight: 500;
  }
  svg {
    color: #29f7f7;
    cursor: pointer;
  }
}

.clickableHeader:hover {
  h4,
  h5,
  svg {
    color: #89ffff;
  }
}
