/* Override the message container background */
.custom-message .ant-message-notice {
  background-color: #191921 !important;
}

/* Override the message content background and text color */
.custom-message .ant-message-notice-content {
  background-color: #191921 !important;
  color: #fff; /* Optional: to make the text white */
}

/* Override the success icon color */
.custom-message .ant-message-custom-content.ant-message-success .anticon {
  color: #0db977 !important;
}
