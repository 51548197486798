.container {
  color: rgb(240, 240, 240);
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: rgb(242, 242, 242);
  }

  h2 {
    margin-bottom: 8px;
  }

  h5 {
    margin-bottom: 2px;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.innerCard {
  div {
    border-bottom: unset !important;
    color: white;
  }
  color: white;
  padding: 10px;
  text-align: left;
  background: linear-gradient(
    -30deg,
    rgba(87, 87, 87, 0),
    rgba(87, 87, 87, 0.5)
  );
  border-radius: 20px;
  border: 1px solid rgba(87, 87, 87, 0.3);
  padding: 20px;
  height: 100%;
  box-sizing: border-box;
  transition: transform 0.2s ease-in-out;
  min-height: 200px;
}

.iconWarning {
  font-size: 24px;
  color: red;
  margin-top: 10px;
}

.iconOk {
  font-size: 24px;
  color: green;
  margin-top: 10px;
}

.chartContainer {
  position: relative;
  width: 100%;
  height: 200px;
}

.noData {
  color: rgba(255, 255, 255, 0.613) !important;
  font-size: 16px;
  text-align: center;
}

.legendContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.legendItem {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.legendColor {
  width: 12px;
  height: 12px;
  margin-right: 8px;
}

.legendValue {
  color: white;
  margin-left: auto;
  font-weight: bold;
}

.legendLabel {
  color: white;
}
