.container {
  color: rgb(240, 240, 240);

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: rgb(242, 242, 242);
  }

  h2 {
    border-bottom: 1px solid white;
    margin-bottom: 8px;
  }

  h5 {
    margin-bottom: 2px;
  }

  h3 {
    margin-bottom: 0;
  }

  span {
    color: rgb(242, 242, 242);
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.5px;
  }
}

.innerCard {
  background-color: transparent; /* Make the background transparent */

  text-align: center;

  div {
    background: transparent !important;
  }

  .table {
    background: transparent; /* Ensure the table's background is transparent */
  }

  th {
    background: rgba(
      255,
      255,
      255,
      0.1
    ) !important; /* Slightly transparent header background */
    color: white !important;
  }

  td {
    background: rgba(
      255,
      255,
      255,
      0.05
    ); /* Slightly transparent body background */
    color: rgb(242, 242, 242); /* White text color for body */
  }
}
