.statisticValue {
  font-size: 20px;
  height: max-content;
}

.prefix {
  margin-right: 4px;
}

.content {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 1px;
}

.contentGreen {
  color: #0db977 !important;
}

.contentRed {
  color: red !important;
}

.contentYellow {
  color: yellow !important;
}
