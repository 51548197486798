.newsContainer {
  padding: 20px;
  border: unset;
}

.newsCard {
  border-radius: 8px;
  overflow: hidden;
  border: unset;
  cursor: pointer;
  background: linear-gradient(
    -30deg,
    rgba(87, 87, 87, 0),
    rgba(87, 87, 87, 0.5)
  );
  box-shadow: -1px -1px 8px rgba(255, 255, 255, 0.15);

  &:hover {
    .newsDescription {
      color: white !important;
    }
    .newsDate {
      color: #aeaeae !important;
    }
  }
}

.newsImage {
  height: 200px;
  object-fit: cover;
}

.newsDate {
  margin-top: 10px;
  font-size: 12px;
  color: #888;
  text-align: right;
}

.newsDescription {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: calc(1.2em * 3); /* 3 lines of text */
  line-height: 1.2em; /* Adjust line height as necessary */
  color: rgb(212, 212, 212);
}
