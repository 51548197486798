.ant-card-meta-title {
  color: rgb(239, 239, 239) !important;
}

.ant-card {
  &:hover {
    .ant-card-meta-title {
      color: #4bc0c0 !important;
    }
  }
}
