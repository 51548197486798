.innerCard {
  background-color: transparent;
  text-align: center;

  div {
    background: transparent !important;
  }

  .table {
    background: transparent;
  }

  th {
    background: rgba(255, 255, 255, 0.1) !important;
    color: white !important;
  }

  td {
    background: rgba(255, 255, 255, 0.05);
    color: rgb(242, 242, 242);

    div {
      color: rgb(242, 242, 242) !important;
    }
  }

  &.empty {
    tr {
      background: rgba(255, 255, 255, 0.05) !important;

      td > div > div {
        color: rgb(242, 242, 242) !important;
      }

      td > div > div {
        color: rgba(255, 255, 255, 0.613) !important;
        font-size: 16px;
      }

      td > div > div:first-child {
        display: none;
      }

      td {
        &:hover {
          background: rgba(255, 255, 255, 0.05) !important;
        }
      }
    }
  }
}

.hoverableTableRow {
  cursor: pointer;
}
