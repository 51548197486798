.chartHeader {
  margin-bottom: 16px;
}

.innerCard {
  div {
    border-bottom: unset !important;
    color: white;
  }
  color: white;
  padding: 10px;
  text-align: left;
  background: linear-gradient(
    -30deg,
    rgba(87, 87, 87, 0),
    rgba(87, 87, 87, 0.5)
  );
  border-radius: 20px;
  border: 1px solid rgba(87, 87, 87, 0.3);
  padding: 20px;
  height: 100%;
  box-sizing: border-box;
  transition: transform 0.2s ease-in-out;
  min-height: 200px;
}
