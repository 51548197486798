@use "src/variables" as *;

.changePasswordContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - $nav-bar-height);
  min-height: calc(100vh - $nav-bar-height) !important;
  background: url("../../assets/bg1.svg") no-repeat center center;
  background-size: cover;
}

.changePasswordCard {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  border-radius: 8px;
  background: linear-gradient(
    -30deg,
    rgba(87, 87, 87, 0),
    rgba(87, 87, 87, 0.5)
  );
  border: unset !important;
  box-shadow: rgba(96, 122, 227, 0.2) 0px 0px 12px;
  h2,
  label {
    color: #ffffff !important;
  }
}

.title {
  text-align: center;
  margin-bottom: 24px;
}

.changePasswordButton {
  width: 100%;
  height: 40px;
  margin-top: 12px;
  font-weight: 600;
  background-color: #de219a !important;
  &:hover {
    background-color: #fa53bd !important;
  }
}
