.root {
  display: flex;
  position: relative;
  gap: 10px;
  width: max-content;
  z-index: 1;
}

.avatar {
  border: 1px solid white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-root {
  position: absolute;
  top: 32px;
  right: -1px;
  width: max-content;
  z-index: 1;
  padding-bottom: 4px;
  position: absolute;
  box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 24px;
  border-radius: 4px;
  background: linear-gradient(90deg, #090015, #350f27, #46002c);
  background: #0f1729;
  border: 1px solid #5b1642;

  .item {
    cursor: pointer;
    height: 36px !important;
    background-color: linear-gradient(
      90deg,
      #090015,
      #130a1f,
      #090015
    ) !important;
    color: #ffffff !important;
    padding: 14px 12px;
    display: flex;
    font-weight: 500;
    align-items: center;
    border-radius: 4px;
    font-size: 14px !important;

    &:hover {
      background-color: #de219a !important;
      color: #242424;
    }
  }
}

.disabled {
  cursor: default !important;
  color: var(--listItemColorForegroundDisabled) !important;
  background-color: var(--listItemColorBackgroundDisabled) !important;
}
