.container {
  height: 100vh;
  background: linear-gradient(90deg, #090015, #130a1f, #090015);
}

.login-box {
  position: relative;
  width: 420px;
  padding: 24px;
  border-radius: 8px;
}

.coverImageContainer {
  background: url("../../assets/bg.svg") no-repeat center center;
  background-size: cover;
}

.loginCard {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  border-radius: 8px;
  background: linear-gradient(
    -30deg,
    rgba(87, 87, 87, 0),
    rgba(87, 87, 87, 0.5)
  );
  border: unset !important;
  box-shadow: rgba(96, 122, 227, 0.2) 0px 0px 12px;
  h2,
  label {
    color: #ffffff !important;
  }
}

.title {
  text-align: center;
  margin-bottom: 24px;
}

.loginButton {
  width: 100%;
  height: 40px;
  margin-top: 12px;
  font-weight: 600;
  background-color: #de219a !important;
  &:hover {
    background-color: #fa53bd !important;
  }
}

.coverImg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  object-fit: cover; /* Ensures the image covers the entire space */
}
