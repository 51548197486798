.container {
  color: rgb(240, 240, 240);
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: rgb(242, 242, 242);
  }

  h1 {
    font-size: 78px;
  }

  h2 {
    margin-bottom: 4px;
    border-bottom: 1px solid white;
  }

  h3 {
    margin-bottom: 0;
    margin-top: 41px;
  }

  h4 {
    margin-top: 0;
    margin-bottom: 2px;
  }

  h5 {
    margin-bottom: 2px;
    color: #b7b7b7;
    font-weight: 500;
  }

  span {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 1px;
  }
}

.chartHeader {
  margin-bottom: 16px;
}

.textContainer {
  margin-top: 4;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 1px;
}

.clickableHeader {
  h5,
  svg {
    color: #29f7f7;
    cursor: pointer;
  }
}

.clickableHeader:hover {
  h5,
  svg {
    color: #89ffff;
  }
}

.innerCard {
  div {
    border-bottom: unset !important;
    color: white;
  }
  color: white;
  padding: 10px;
  text-align: left;
  background: linear-gradient(
    -30deg,
    rgba(87, 87, 87, 0),
    rgba(87, 87, 87, 0.5)
  );
  border-radius: 20px;
  border: 1px solid rgba(87, 87, 87, 0.3);
  padding: 20px;
  height: 100%;
  box-sizing: border-box;
  transition: transform 0.2s ease-in-out;
  min-height: 200px;
}
