@use "src/variables" as *;

.container {
  background: linear-gradient(90deg, #090015, #130a1f, #090015);
  min-height: calc(100vh - $nav-bar-height) !important;
  padding: 20px;
  display: flex;
  width: 100%;
  justify-content: center;
}

.logoContainer {
  width: 160px;
  height: 50px;
  display: flex;
}

.logo {
  width: 100%;
  object-fit: contain;
}

.layoutConstrain {
  max-width: 1500px;
  padding: 64px;
  width: 100%;
}

.searchContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  justify-content: center;
  align-items: center;
  position: relative;
}

.searchInput {
  margin-bottom: 20px;
  width: 500px;
  height: 50px;
  border: 1px solid rgb(193, 193, 193);
  border-radius: 25px;
  background-color: #1e1e1e;
  color: #ffffff;
}

.searchInput input::placeholder {
  color: rgb(193, 193, 193);
}

.dropdownContainer {
  position: relative;
}

.dropdownHeader {
  font-size: 16px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #969696;
  padding: 0 20px;
  border-radius: 25px;
  cursor: pointer;

  &.active {
    color: white;
  }
}

.dropdownIcon {
  margin-left: 10px;
}

.dropdownMenu {
  position: absolute;
  top: 30px;
  left: calc(-200px + 50%);
  width: 400px;
  background-color: #191921;
  border-radius: 4px;
  z-index: 1000;
  border: 1px solid rgba(68, 68, 68, 0.6);
}

.dropdownItem {
  padding: 14px;
  cursor: pointer;
  color: rgb(202, 202, 202);
}

.dropdownItem:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

.providerBoxContent {
  box-shadow: rgba(96, 122, 227, 0.2) 0px 0px 24px;
  border: 1px solid rgba(96, 122, 227, 0.2);
  width: 100%;
  display: flex;
  height: 190px;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  background: url("../../assets/provider-background.svg") 50%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  color: white;

  &.allowHover {
    cursor: pointer;
  }

  b {
    padding: 8px;
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: bolder;
    margin-top: 10px;
    transition: transform 0.2s ease-in-out;
  }

  .logoContainer {
    transition: transform 0.2s ease-in-out;
  }

  &:hover.allowHover b {
    transform: translateY(-6px);
  }
  &:hover.allowHover .logoContainer {
    transform: translateY(-6px);
  }
}
